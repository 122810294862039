<template>
<!-- 상태 변경 팝업 -->
<transition name="modal" appear>
    <div class="modal modal-overlay" v-if="onModal" @click.self="handleWrapperClick">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div>
                        <p class="bold dp-inblock h-30px font-23">상담사 미리보기</p>
                        <div class="con">
                            <div class="con_table mt-20">
                                <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00 w-100per">
                                    <tbody>
                                        <tr>
                                            <th>상담사</th>
                                            <td class="left pl-20" style="min-width: 200px;">
                                                {{user.name}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>성별</th>
                                            <td class="left pl-20">
                                                {{user.gender}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>센터</th>
                                            <td class="left pl-20">
                                                {{user.centerName}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>전문분야</th>
                                            <td class="left pl-20">
                                                {{user.proName}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="btns3 mgB80">
                                    <!-- <a class="btn_sms mr-10 pointer" @click="$emit('close')">닫기</a> -->
                                    <a class="btn_sms mr-10 pointer" v-if="user.permission !== 'Y'" v-on:click="putPermission">승인하기</a> <a class="btn_sms mr-10 pointer" @click="$emit('update:onModal', !onModal)">닫기</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
export default {

    props: {
        onModal: {
            type: Boolean,
            require: true,
            default: false
        },
        user: {
            type: Object,
            require: false
        },
        idx: {
            type: Number,
            require: true
        }
    },

    data: () => ({

    }),

    mounted() {

    },

    methods: {

        handleWrapperClick(){
            this.$emit('update:onModal', false)
        },

        putPermission() {
            this.$emit('permission', this.idx)
        }

    }
}
</script>

<style lang="scss" scoped>
.modal {
    &.modal-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 30;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    &-window {
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
    }

    &-content {
        padding: 10px 20px;
        background: #fff;
    }

    &-footer {
        // background: #ccc;
        padding: 10px;
        text-align: right;
    }
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.4s;

    .modal-window {
        transition: opacity 0.4s, transform 0.4s;
    }
}

.modal-leave-active {
    transition: opacity 0.4s ease 0.2s;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;

    .modal-window {
        opacity: 0;
        transform: translateY(-20px);
    }
}
</style>
